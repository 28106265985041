@font-face {
  font-family: 'Nike TG';
  font-style: normal;
  font-weight: 400;
  src: url(https://www.nike.com/assets/ncss/3.2/dotcom/fonts/257c802f-349c-4b4d-aefa-546d5de15ec6.eot),url(https://www.nike.com/assets/ncss/3.2/dotcom/fonts/257c802f-349c-4b4d-aefa-546d5de15ec6.eot?#iefix) format('embedded-opentype'),url(https://www.nike.com/assets/ncss/3.2/dotcom/fonts/257c802f-349c-4b4d-aefa-546d5de15ec6.woff2) format('woff2'),url(https://www.nike.com/assets/ncss/3.2/dotcom/fonts/257c802f-349c-4b4d-aefa-546d5de15ec6.woff) format('woff'),url(https://www.nike.com/assets/ncss/3.2/dotcom/fonts/257c802f-349c-4b4d-aefa-546d5de15ec6.ttf) format('truetype'),url(https://www.nike.com/assets/ncss/3.2/dotcom/fonts/257c802f-349c-4b4d-aefa-546d5de15ec6.svg#257c802f-349c-4b4d-aefa-546d5de15ec6) format('svg')
}

@font-face {
  font-family: 'Nike Futura';
  font-style: normal;
  font-weight: 400;
  src: url(https://www.nike.com/assets/ncss/3.2/dotcom/fonts/nike-futura-extra-bold-v200.eot),url(https://www.nike.com/assets/ncss/3.2/dotcom/fonts/nike-futura-extra-bold-v200.eot?#iefix) format('embedded-opentype'),url(https://www.nike.com/assets/ncss/3.2/dotcom/fonts/nike-futura-extra-bold-v200.woff2) format('woff2'),url(https://www.nike.com/assets/ncss/3.2/dotcom/fonts/nike-futura-extra-bold-v200.woff) format('woff'),url(https://www.nike.com/assets/ncss/3.2/dotcom/fonts/nike-futura-extra-bold-v200.ttf) format('truetype'),url(https://www.nike.com/assets/ncss/3.2/dotcom/fonts/nike-futura-extra-bold-v200.svg#nike-futura-extra-bold-v200) format('svg')
}

.drawerSection::before, .drawerSection::after {
  content:"\00a0\00a0\00a0\00a0\00a0";
  text-decoration:line-through;
}

h1, h2, h3 {
  font-weight: normal;
}

/*
body {
  margin: 0;
  font-family: 'Helvetica Neue Regular', Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiDrawer-root nav ul {
  list-style: none;
  margin: 0;
  padding-left: 16px;
}

.MuiDrawer-root nav li {
  margin: 8px 0;
}

.MuiDrawer-root nav a {
  color: black;
  text-decoration: none;
}

.MuiDrawer-root nav a:hover {
  color: #5197D6;
  cursor: pointer;
}


main p {
  font-size: 14px;
}

main .code {
  font-size: 13px;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  background-color: #f5f5f5;
  padding: 8px 16px;
  border: 1px solid #eee;
  border-radius: 3px;
}

main h1 {
  font-family: 'Nike TG';
  font-size: 48px;
  font-weight: 400;
}

/*
main h1 a {
  color: black;
  text-decoration: none;
}


main h2 {
  font-size: 28px;
  font-weight: 400;
}

/*
main h2 a {
  color: black;
  text-decoration: none;
}

main section {
  margin-bottom: 48px;
}


#section-button Button {
  margin: 20px;
}

/*
#section-buttongroup .MuiButtonGroup-root {
  margin: 20px;
}

#section-checkbox th {
  font-size: small;
  text-align: right;
}

#section-textfield .MuiTextField-root {
  margin: 20px;
}
*/
